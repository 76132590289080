import React from 'react'
import ReactDOM from 'react-dom'

import {
  useParams
} from "react-router-dom";


import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactMarkdown from 'react-markdown'


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import {isMobile} from 'react-device-detect';

import homeImage from '../images/IMG_0016-20.jpg';

const styles = theme => ({
  home: {
    padding: 0,
    marginTop: 100,
    marginBottom: '50px',
    maxWidth: "none",
    width: "100%",
  },

  titleSection: {
      backgroundColor: `#FFF`,
      backgroundSize: "cover",
      height: "200px",
      maxWidth: "none",
      padding: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
  },

  media: {
    height: "400px",
  },

  recipeHead: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",

  },
  photo: {
     height: 700,
  },

  moiblePhoto: {
    width: '100%',
  },

  recipeHeadText: {
    flexWrap: "wrap",
    flex: 1,
    flexDirection: "column",
    marginTop: 25,
  },

  recipeTitle: {
    flex: 1,
  },

  recipePage: {
    margin: 20,
  },

  headnote: {
    color: "#AAA",
    flex: 1,
    fontSize: '13pt',
    margin: 20,
    maxWidth: 600,
  },

  sodium: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 20,
  },

  ingredients: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 20,
    flexWrap: "wrap",
    maxHeight: "400px",
  },

  ingredient: {

  },

  recipeMethod: {
    marginTop: 10,
  },
  subHead: {
    fontSize: "13pt",
    color:  "#AAA",
    textTransform: "uppercase",
    fontWeight: "600px",
  },
});


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

class Recipe extends React.Component {


  constructor(props) {
    super(props)
      //

    this.state = {
      recipe: {
          images: []
      },
      photos: [],
      ingredients: [],
      viewPortWidth: 0,
      viewPortHeight: 0,
    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    await this.getRecipe(id);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ viewPortWidth: window.innerWidth, viewPortHeight: window.innerHeight });
  }

  async getRecipe(id) {
     fetch(`/api/v1/recipes/${id}`, {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log('recipe', data);
      this.setState({recipe: data, photos: data.photos, ingredients: data.recipe_ingredients});
    });
  }

  gotoRecipe() {

  }


  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.home}>

        <Container className={classes.recipeHead}>
          <div style={isMobile ? {width: '100%', overflow: 'hidden', height: 500, display: 'flex', justifyContent: 'center'} : {flex: 1}}>
            <img
              className={isMobile ? classes.mobilePhoto : classes.photo}
              src={isMobile ? this.state.recipe.thumb_image : this.state.recipe.hero_image }
              alt={this.state.recipe.name} />
          </div>

          <Container className={classes.recipeHeadText}>
            <div className={classes.recipeTitle}>
              <Typography
                align="center"
                variant="h2"
                color="secondary">
                {this.state.recipe.name}
              </Typography>
            </div>

            <div className={classes.headnote}>
              <ReactMarkdown>
                {this.state.recipe.headnote}
              </ReactMarkdown>
            </div>


            <div className={classes.sodium}>
              Sodium per serving:&nbsp;
               { this.state.recipe.sodium_per_serving }
              <br/>
              Number of servings:&nbsp;
                {this.state.recipe.number_servings}
            </div>

            {(!!this.state.recipe.page_number &&
              <div className={classes.recipePage}>
                {`This recipe can be found on page ${this.state.recipe.page_number} `}
                of <a href="/book">{this.state.recipe.book_name}</a>
              </div>
            )}


            {(this.state.ingredients.length > 0 &&
            <div className={classes.ingredients}>
              <h5 className={classes.subHead}>
                Ingredients
              </h5>
              {this.state.ingredients.map((ingredient, index) => (
                  <div
                    key={index}
                    className={classes.ingredient}>
                    {ingredient.display_string}
                  </div>
              ))}
            </div>
            )}
          </Container>


        </Container>

        {(!!this.state.recipe.recipe_method &&
          <Container className={classes.recipeMethod}>
            <h5 className={classes.subHead}>
              Preparation
            </h5>
            <ReactMarkdown>
              {this.state.recipe.recipe_method}
            </ReactMarkdown>
        </Container>
        )}

      </Container>
    )
  }
}


export default withStyles(styles)(Recipe);
