import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles, Theme } from '@material-ui/core/styles';


class TabPanel extends React.Component {
 constructor(props) {
   console.log((props))
    super(props)
  }

  render() {
    const { children, value, index, ...other } = this.props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            {children}
          </Box>
        )}
      </div>
    );
  }
}

export default TabPanel;