import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Icon from '@material-ui/core/Icon';
import BookIcon from '@material-ui/icons/Book';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ReactMarkdown from 'react-markdown'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../stylesheets/carouselOverride.css';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {isMobile} from 'react-device-detect';

import homeImage from '../images/IMG_0035-4.jpg';
import placeholderImage from '../images/IMG_0008-30.jpg';
import { responsive } from '../stylesheets/responsiveBreakpoints';

const styles = theme => ({
  home: {
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },
  about: {
    maxWidth: "none",
    padding: 30,
    width: "100%",
  },

  titleSection: {
      backgroundImage: `url(${homeImage})`,
      backgroundSize: "cover",
      height: 350,
      maxWidth: "none",
      padding: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderBottom: "20px solid #ff6f00",
  },
  overlay: {
    position: 'relative',
    top: 0,
    height: 350,
    background: "linear-gradient(-200deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 53%, rgba(0, 0, 0, 0.2) 100%)"
  },

  homeTitle: {
    color: "#FFF",
    fontSize: '14pt',
    margin: 'auto',
    position: "relative",
    top: 125,
    textAlign: "center",
    width: "40%",
  },

  titleDetail: {
  },

  mobileTitle: {
    position: "relative",
    top: 75,
    color: "#FFF",
    textAlign: "center",
    margin: 'auto',
    width: "90%",
  },

  latestHeader: {
    padding: "20px",
    textAlign: "center",
  },

  carouselCard: {
    height: "auto",
    padding: 5,
    maxWidth: '100%',
  },

  carouselContainer: {
    backgroudColor: "#EEE",
    marginBottom: "50px",
  },

  recipeCard: {
    //height: "500px",
  },

  photo: {
    maxWidth: '100%',
    height: '500px',
    borderRadius: '.25rem!important',
    verticalAlign: "middle",
    borderStyle: "none",
    backgroundSize: 'cover',
  },
  caption: {
    width: '100%',
    height: "65px",
    position: "absolute",
    bottom: 0,
    backgroundColor: "rgba(254,248,240, .80)",
    color: "rgba(0,0,0,.75)",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  captionText: {
    flex: 5,
    fontWeight: "600",
    textTransform: "uppercase",
  },

  sodiumBubble: {
    backgroundColor: "#ff6f00",
    borderRadius: "5px 20px 5px",
    color: "#FFF",
    flex: 1,
    fontSize: "8pt",
    fontWeight: "600",
    height: "60px",
    maxWidth: "60px",
    paddingTop: 20,
    textAlign: "center",
    textTransform: "uppercase",

  },
  book: {
    color: theme.palette.secondary.main,
    fontSize: "10pt",
  },
});


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recipes: [],
    }
  }

  async componentDidMount() {
    await this.getRecipes();
  }


  async getRecipes() {
     fetch('/api/v1/recipes/latest', {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({recipes: data});

    });
  }

  gotoRecipe() {

  }


  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.home}>

        <Container className={classes.titleSection}>
          <div className={classes.overlay}>

          <div className={isMobile ? classes.mobileTitle : classes.homeTitle}>
             <Typography
              align="center"
              variant="h3"
              color="primary">Hi There!</Typography>
             <div className={classes.titleDetail}>
             This website is a companion to my <a href="/book" style={{color: "#FFF", fontWeight: 100}}>Low Sodium, Big Flavor</a> cookbook, to make it easier to mix and
             match recipes manage your sodium consumption throughout your day. You'll find references to recipes in the book, as well as new, complete
             recipes to add in to your meals and suggested meal plans to take a little of the work out of your day.
             </div>
          </div>
          </div>
        </Container>

        <Container className={classes.latestHeader}>
            <Typography
              align="center"
              variant="h4"
              color="secondary">
              Featured Recipes
            </Typography>
        </Container>

        <Carousel
          autoPlay={false}
          containerClass="carouselContainer"
          deviceType={this.props.deviceType}
          draggable={false}
          keyBoardControl={true}
          infinite={true}
          itemClass={classes.carouselCard}
          partialVisible={true}
          //removeArrowOnDeviceType={["tablet", "mobile"]}
          responsive={responsive}
          showDots={false}
          ssr={true} // means to render carousel on server-side.
          swipeable
          transitionDuration={500}
        >

          {this.state.recipes.map((item, index) => (
            <Link key={index} to={`/recipe/${item.id}`}>
              <div
                className="recipeCard"
                >
                <div
                  className={classes.photo}
                  style={{backgroundImage: `url(${item.thumb_image})` }}
                  alt={item.name} />
                <div className={classes.caption}>
                  <div className={classes.captionText}>
                    {item.name}
                    {( !!item.page_number &&
                    <BookIcon titleAccess={`You can find this recipe in ${item.book_name}`} className={classes.book}/>
                    )}
                  </div>
                  {( !!item.sodium_per_serving &&
                  <div
                    title={`${item.sodium_per_serving} sodium per serving`}
                    className={classes.sodiumBubble}>
                    {item.sodium_per_serving}
                  </div>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </Carousel>

      </Container>
    )
  }
}


export default withStyles(styles)(Home);
