import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ReactMarkdown from 'react-markdown'
import HeadPanel from '../components/HeadPanel';


const styles = theme => ({
  home: {
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },

  about: {
    marginTop: "25px",
  },

});

const eatingOutText = `When eating at home, I’ve got a pretty good sense of how on target
I am with my sodium intake, and I’m sure you will be too once you get down a few basics.
But, eating at home isn’t always an option, so it’s good to understand how to approach restaurant food.

One thing I quickly learned after grabbing a quick bite at an airport Chile’s on a short layover
is that my instinct on what would be low sodium was way off base. With a quick skim of the menu,
I saw in the “Lighter Choices” section a southwestern grilled chicken salad. It was basically grilled chicken,
a little salsa, some beans and rice and lettuce. Maybe it was the “lighter” or the grilled chicken idea,
but it seemed like a reasonable choice. I expected it would still be higher than something I would make
for myself at home but gave it a go. After it arrived, and I started eating, I belatedly remembered that
many chain restaurants now post full nutritional information for the items on their menus on their websites,
so I pulled out my phone to see how I had done.

Then, I stopped eating, less than ¼ of the way through the salad. The dish showed up as having over 3,000 mg
of sodium! Since then, I’ve learned that a lot of restaurants brine their chicken which can significantly
increase sodium content, and of course, canned beans are quite high in sodium. As it turns out, I could have
just ordered a plain burger (about1500 mg… still too high, but significantly better!), taken off the top bun
and made sure to not add any condiments or pickles. Instead, I left the rest of the salad, and went to find
some fresh fruit.

The FDA guidelines have required restaurants with more than 20 locations to provide nutritional
information since May 7, 2108, and many are already in compliance. Having the nutritional information posted
online for these types of restaurants is incredibly helpful in understanding what to avoid at restaurants in
general. Calorie information may be printed directly on the menu or online, but the full nutritional
information may be on separate printed materials, so ask if you don’t see it. Also, checkout the
[Hacking Salt blog](https://www.hackingsalt.com/category/dining-out/) posts about some of the better low sodium
chain restaurants offerings.

Here’s what I do when faced with a new menu, particularly in a place that doesn’t post nutritional information
anywhere (which is most of the places that I eat out).

Are there simple grilled meats or fish? Most meat has only about 90mg of sodium for a 4-ounce
serving, unless it is brined or seasoned. I’ll usually go for a grilled steak or fish option,
or cheese-less burger which won’t be brined and ask for no seasoning to be added. I love sushi
restaurants, where I can indulge in sashimi and a few pieces of nigiri and some steamed rice,
but I skip the soy sauce. Even the low sodium version is fairly high in sodium.

Is there a simple pasta? Pasta in a restaurant will be cooked in heavily salted water, so it will
have more sodium than pasta cooked at home without it, but it’s not significant (Olive Garden’s
un-sauced pasta shows 10 mg per serving). A simple fresh Pomodoro (tomato) sauce or a primavera
(freshly sautéed veggies) should be under 1000 mg but be sure to ask for those without any cheese.
But be careful with most other pasta sauces which are quite high in sodium, especially if they
contain sausage, bacon, cheese or cream. Stick to the most basic tomato sauce or butter and wine sauces,
and again, ask for no cheese. Also, pasta servings in many restaurants are much larger than a single
serving… so you may want to split the entrée or bring home leftovers.

Are there any salads that could work? Salads are trickier than you may initially think.
Salad dressings are high in sodium, as are most of the additions that make a salad feel like a meal
(like cheese, ham, bacon, salted or candied nuts, pickled veggies).

What are the sides? You’ll want to skip the fries, chips or slaw, and see if you can get a simple
green salad with oil and vinegar or a bowl of fruit.

Here’s what I avoid:

**Sauces** (including salad dressings), other than very simple sauces like a Pomodoro sauce.
Sauce is one of sodium’s favorite hiding places. If something comes with a sauce, see if
you can get it on the side, so you can get a tiny amount of the flavor without an overwhelming
amount of sodium. For reference, a side of Chile’s honey mustard sauce has 360mg of sodium
and is one of the lower sodium options. Their house BBQ sauce is 750mg for a side.

**Soup.** Soup is basically a meal of sauce. Unless you can see the sodium count, it’s
best to avoid it. A bowl of chicken enchilada soup is about 1660mg; a bowl of Chile’s
clam chowder is only 780mg which is still about ½ of the recommended sodium for the day.

**Cured or processed meats.** Unfortunately, there’s no way around it. Cured meats are cured
with sodium, and even meats that aren’t cured like “lunch” meats (including turkey) and
bacon are all out. A bratwurst has about 560mg of sodium (without condiments); a single
serving of oven-roasted turkey lunch meat has 490mg.

**Fermented foods.** Most fermented foods, such as sauerkraut and kimchi use large amounts
of salt to control fermentation and bacterial growth and are high in sodium.

**Baked goods.** Both those raised with yeast and those raised by chemical leavening are
potentially quite high in sodium. Yeast raised breads (sandwich bread, baguette, bagels)
typically use salt to control the rising process (as well as provide a lot of flavor),
and chemical leavened baked goods (muffins, biscuits, quick breads, cookies, flour tortillas)
use baking soda (or powder) which is very high in sodium. A slice of sandwich bread typically
contains between 100 and 200mg; a 10-inch flour tortilla can have up to 420mg.

**Sandwiches.** Sandwiches (and pizza/flatbreads) are baked goods plus processed meats, cheese
and sauces, each of which are on the high side on their own, and they add up quickly. If you
are really craving a sandwich, try an open-faced tuna sandwich, and be sure to skip the cheese.
At Subway, the 6-inch Veggie Delite sandwich is only 280mg (with no additions like cheese…)

**Cheese.** Cheese is quite high in sodium (and most cheese that is low in sodium doesn’t taste
very good!) In most restaurants though, it’s pretty easy to ask for no cheese. Swiss cheese is
one of the lowest sodium cheese options, at 54mg a slice.

**Sides.** Unless it’s something like fresh fruit or a green salad, it’s usually safest to skip the sides.
Even if it’s just a side of cooked vegetables, they are most likely salted.

**Desserts.** I skip most desserts for the same reason I skip most baked goods. There’s sneaky sodium in them.
A good dessert option would be sorbet, or even better… fresh fruit.


`


class EatingOut extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.home}>

        <HeadPanel title="How to Eat Out Low Sodium"/>

        <Container className={classes.about}>

        <div align="left">
            <ReactMarkdown
              children={eatingOutText}>
            </ReactMarkdown>
        </div>
      </Container>
     </Container>
    )
  }
}

export default withStyles(styles, { withTheme: true })(EatingOut);
