import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ReactMarkdown from 'react-markdown'
import HeadPanel from '../components/HeadPanel';
import {isMobile} from 'react-device-detect';

import lsbgfCover from '../images/lsbf_cover.jpg';
import doughnutsCover from '../images/doughnuts_cover.jpg';
import realSnacksCover from '../images/real_snacks_cover.jpg';
import eggCover from '../images/egg_cover.jpg';
import avocadoCover from '../images/avocado_cover.jpg';
import cookieDoughCover from '../images/cookie_dough_cover.jpg';

const styles = theme => ({
  home: {
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },

  about: {
    marginTop: "25px",
  },

  photo: {
     height: 500,
  },

  mobilePhoto: {
    height: 350,
    margin: 'auto'
  },


  otherBooksWrapper: {
    paddingTop: "50px",
  },
  otherBooks: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingTop: "20px",
  },

  otherPhoto: {
    height: "300px",
  },

  bookText: {
    flex: 1,
    padding: "20px",
  },


  bookTextWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingTop: "50px",
  },

  bookWithLinks: {
    minWidth: 300,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 10,
    justifyContent: "flex-start",
    alignItems: "center",
  },

  links: {
    marginTop: 5,
  },

  disclosure: {
    color: "#888",
    marginTop: 40,
    fontSize: 12,
  }

});


const bookText = `If you are on a low-sodium diet and
tired of eating bland food, these 115 recipes for pantry staples
and daily meals are just what you need. It's time to enjoy food again!

*Low Sodium, Big Flavor* is my 7th cookbook, and is full of flavorful, low sodium recipes that
I think you will love. In this book, you will find recipes for homemade condiments,
spice blends, dressings, cheeses, breads, and prepared meats, and plenty of recipes for daily meals (even dessert!)
that when combined contain less than a total of 2,000 mg of sodium.

There is no need to feel deprived on a low-sodium diet. These recipes will help you
love the food you eat while at the same time feel good about taking care of your health.`


class Book extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (

      <Container className={classes.home}>

        <HeadPanel title="Low Sodium, Big Flavor Cookbook"/>

        <Container className={classes.about}>
          <Container className={classes.bookTextWrapper}>
            <img
              className={isMobile ? classes.mobilePhoto : classes.photo}
              src={lsbgfCover}
              alt={'Low Sodium, Big Flavor cover image'} />

            <div align="left"  className={classes.bookText}>
              <ReactMarkdown
                children={bookText}>
              </ReactMarkdown>
              <p />
              <Link
                color='secondary'
                target="_blank"
                underline='none'
                href="https://www.amazon.com/gp/product/1632172860/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1632172860&linkCode=as2&tag=fullycomplete-20&linkId=bf3b97874ba17b9d02340cd6ca9d83fc">Amazon</Link> &nbsp;|&nbsp;&nbsp;
              <Link
                color='secondary'
                target="_blank"
                underline='none'
                href="https://www.powells.com/searchresults?keyword=9781632172860">Powell's</Link> &nbsp;|&nbsp;&nbsp;
              <Link
                color='secondary'
                target="_blank"
                underline='none'
                href="http://www.indiebound.org/product/info.jsp?isbn=9781632172860">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
              <Link
                color='secondary'
                target="_blank"
                underline='none'
                href="https://www.barnesandnoble.com/w/low-sodium-big-flavor-lara-ferroni/1137256944?ean=9781632172860">Barnes & Noble</Link>

            </div>


          </Container>
          <Container className={classes.otherBooksWrapper}>
            <Typography
              align="left"
              variant="h3"
              color="secondary">
              Some of My Other Cookbooks
            </Typography>

            <Typography
              align="left">
              These aren't low sodium books, but you might enjoy them!
            </Typography>
            <div className={classes.otherBooks}>
              <div className={classes.bookWithLinks}>
                <img
                    className={classes.otherPhoto}
                    src={doughnutsCover}
                    alt={'Doughnuts cover image'} />
                <div className={classes.links}>
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.amazon.com/gp/product/B07168RXP6/ref=as_li_tl?ie=UTF8&tag=fullycomplete-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07168RXP6&linkId=2fb2ec5056c70beab972c81723c95064">Amazon</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.powells.com/searchresults?keyword=9781632171252">Powell's</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.indiebound.org/book/9781632171252">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.barnesandnoble.com/w/doughnuts-lara-ferroni/1127085451;jsessionid=A7E28F186882E6B803F551A3CB314468.prodny_store01-atgap12?ean=9781632171252">Barnes & Noble</Link>
                </div>
              </div>

              <div className={classes.bookWithLinks}>
                <img
                    className={classes.otherPhoto}
                    src={avocadoCover}
                    alt={'An Avocado A Day cover image'}/>
                <div className={classes.links}>
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.amazon.com/gp/product/1632170817/ref=as_li_tl?ie=UTF8&tag=fullycomplete-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1632170817&linkId=a1ea95b02367805f5e1e14ecb6f5edc5">Amazon</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.powells.com/searchresults?keyword=9781632170811">Powell's</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.indiebound.org/book/9781632170811">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.barnesandnoble.com/w/an-avocado-a-day-lara-ferroni/1123948924?ean=9781632170811">Barnes & Noble</Link>
                </div>
              </div>


              <div className={classes.bookWithLinks}>
                <img
                  className={classes.otherPhoto}
                  src={eggCover}
                  alt={'Put an Egg On It cover image'} />
                <div className={classes.links}>
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.amazon.com/gp/product/1570618798/ref=as_li_tl?ie=UTF8&tag=fullycomplete-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1570618798&linkId=def6bdd64634e733e1015ef181dabf8c">Amazon</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.powells.com/searchresults?keyword=9781570618796">Powell's</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.indiebound.org/book/9781570618796">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.barnesandnoble.com/w/put-an-egg-on-it-lara-ferroni/1115072475?ean=9781570618796">Barnes & Noble</Link>
                </div>
              </div>


              <div className={classes.bookWithLinks}>
                <img
                  className={classes.otherPhoto}
                  src={realSnacksCover}
                  alt={'Real Snacks cover image'} />
                <div className={classes.links}>
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.amazon.com/gp/product/1570617880/ref=as_li_tl?ie=UTF8&tag=fullycomplete-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=1570617880&linkId=7265e545d9e4f56248733a9740d61769">Amazon</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.powells.com/searchresults?keyword=9781570617881">Powell's</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.indiebound.org/book/9781570617881">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.barnesandnoble.com/w/real-snacks-lara-ferroni/1110873199?ean=9781570617881">Barnes & Noble</Link>
                </div>
              </div>

              <div className={classes.bookWithLinks}>
                <img
                  className={classes.otherPhoto}
                  src={cookieDoughCover}
                  alt={'Cookie Doughlicious cover image'} />
                <div className={classes.links}>
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.amazon.com/gp/product/B00IHGVRQU/ref=as_li_tl?ie=UTF8&tag=fullycomplete-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00IHGVRQU&linkId=b82d6fcb47fc998ff771d2be6742f36e">Amazon</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.powells.com/book/cookie-doughlicious-50-cookie-dough-recipes-for-candies-cakes-more-9780762445387">Powell's</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.indiebound.org/book/9780762445387">Indie Books</Link> &nbsp;|&nbsp;&nbsp;
                  <Link
                    color='secondary'
                    target="_blank"
                    underline='none'
                    href="https://www.barnesandnoble.com/w/cookie-doughlicious-lara-ferroni/1115773136?ean=9780762451760">Barnes & Noble</Link>
                </div>
              </div>

            </div>


          </Container>
          <Container className={classes.disclosure}>
            <hr />
            <div>
              I am a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for to earn fees by linking to Amazon.com and affiliated sites.
            </div>
          </Container>
        </Container>
     </Container>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Book);
