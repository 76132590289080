import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Main from "../pages/main";
import Home from "../pages/home";
import Recipes from "../pages/recipes";
import Recipe from "../pages/recipe";
import MealPlans from "../pages/meal_plans";
import MealPlan from "../pages/meal_plan";
import EatingOut from "../pages/eating_out";
import About from "../pages/about";
import Book from "../pages/book";
import Ingredients from "../pages/ingredients";

import Header from "../pages/header";
import Footer from "../pages/footer";

export default (
  <div>

  <Router>
  <Header />

<TransitionGroup>
    <CSSTransition
      key={location.key}
      classNames="fade"
      timeout={1000}>


    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/recipes" exact component={Recipes} />
      <Route path="/recipe/:id" exact component={Recipe} />
      <Route path="/meal_plans" exact component={MealPlans} />
      <Route path="/meal_plan/:id" exact component={MealPlan} />
      <Route path="/eating_out" exact component={EatingOut} />
      <Route path="/ingredients" exact component={Ingredients} />
      <Route path="/about" exact component={About} />
      <Route path="/book" exact component={Book} />

    </Switch>
    </CSSTransition>
  </TransitionGroup>
  </Router>
  <Footer />
  </div>
);