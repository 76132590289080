import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ReactMarkdown from 'react-markdown'
import HeadPanel from '../components/HeadPanel';


const styles = theme => ({
  home: {
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },

  about: {
    marginTop: "25px",
  }
});

const aboutText = `Several years ago, I was diagnosed with Meniere’s disease,
a syndrome most know for a combination of hearing loss and drop vertigo attacks.
My diagnosis came after 2 years of intermittent hearing loss/tinnitus in one ear.
So far, I’m one of the lucky ones that hasn’t had any drop vertigo attacks
(yes, that’s wood you hear me knocking on).
But my balance is not what it used to be, and I have associated
BPPV (benign paroxysmal position vertigo) attacks and some serious brain fog during my episodes.

The good news is that while it can be incredibly debilitating,
Meniere’s isn’t fatal. It’s something you live with.
And it’s something that many sufferers can control with a change in diet and lifestyle.

The treatment: Cut out caffeine and cut back significantly on alcohol and keep sodium
to 1500mg or less. The first two are luxuries that I really enjoy but are also easy
to part with or reserve for special occasions. Cutting back on sodium however meant
a pretty big rethink in how I eat.

*It’s the end of the world as I knew it…*

I’m a food writer/photographer… and more importantly, I just really love to eat
good food. Whether it’s at home or out, I’ve been incredibly fortunate that
I’ve never had any food allergies of note. Of course, I have dear friends
who have serious reactions to wheat or nuts or dairy, and it was always fun
coming up with recipes that worked for them. My Real Snacks cookbook contained
gluten free versions and vegan versions of almost all of the recipes.
But I always had the luxury of eating without thought.

Suddenly, I had to do some thinking. And my first thought was
**what the @#!%$ am I going to do?** I realized I had no idea how much
sodium was in the foods that I ate, so I did what you do,
and started to Google. How much sodium is in a teaspoon of salt?
Google’s answer did not make me happy… 1 teaspoon of salt is 2000 mg.
That’s 25% more than I’m shooting for in a day now, and that didn’t even seem
like that much. Doesn’t every recipe call for about a teaspoon of salt?
Ok, well, I reasoned, I can just cut way down on the salt in recipes… that should do it right?

Unfortunately, there are all sorts of sneaky sodium sources that I
never even realized. While the recommended sodium allowance for a
normal non-Menieresy person is 2500 mg a day, after a bit of study,
I’ve come to seriously doubt that most people are anywhere close to that number.
One slice of cheese pizza is about 1500 mg. A plain bagel is 400 mg.
*That blueberry muffin that doesn’t taste salty at all?* It probably has about
500 mg thanks to the baking powder. Heck, a couple cups of kale have 50 mg,
not to mention all that lovely dressing you need to make it taste good.
Even celery, which is like eating crunchy air, is 32 mg a stalk.
My guess is that in my old lifestyle, I’d easily consume 4000-6000 mg in a
day, even if I wasn’t indulging.

The first time I went grocery shopping after my diagnosis,
I found myself just standing in the aisle, in shock,
thinking I’d never be able to eat anything good again.
Everything I picked up seemed to have far more sodium than
I expected that it would. I had numbers flying through my brain,
trying to figure out what I could eat that wouldn’t blow through
all my daily “sodium calories”? It didn’t help that I had foggy
Meniere’s brain at the time. My husband, trying to help, asked me
what I wanted him to get and I nearly burst into tears. We managed
to pick up a few random things, (including a 0-sodium hot sauce!
And 0 sodium sweet plantain chips!) and I made myself some dinner
that tasted like… well… tasted like it needed salt.

And that was just eating at home. Eating in a restaurant we even more baffling.
While many restaurants now do a great job of catering to those that need a vegan
or gluten free diet, asking for something to be prepared without salt is more
likely to get you a deer in the headlights look. At the time of my diagnosis,
6 out of 7 of our dinners were in a restaurant. That was going to have to change.

I spent about a week just wallowing in self-pity, and painstakingly monitoring
my sodium intake, on most days keeping it to under 1000 mg by mostly eating
super plain food. I ate a lot of oatmeal that first week. Lots and lots of oatmeal.

But it got better, and slowly through a combination of learning how to amp up flavor
with other spices and acids, and a natural adjustment in my palate, eating has started
to become a joy again and not (for the most part) a stress-induced nightmare.

Through this whole process, I started documenting what I was loving to cook and eat... and the result of that
is my latest cookbook, [**Low Sodium, Big Flavor**](/book) and this companion website, with tools to make it easier for you
to cook low sodium recipes (plus more full recipes that didn't make the book!).

I hope it's helpful for you!

If I can be of help, shoot me an email at lowso (at) laraferroni.com.

*Lara*

`


class About extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.home}>
        <HeadPanel title="About Me"/>
        <Container className={classes.about}>

          <div align="left">
              <ReactMarkdown
                children={aboutText}>
              </ReactMarkdown>
          </div>
       </Container>
     </Container>
    )
  }
}

export default withStyles(styles, { withTheme: true })(About);
