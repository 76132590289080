import React from 'react'
import PropTypes from 'prop-types';


import ReactDOM from 'react-dom'

import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/core/styles';
import App from "../components/App";
import Main from '../pages/main';
import theme from '../theme/theme';

import Header from '../pages/header';
import Home from '../pages/home';
import Recipes from '../pages/recipes';


class LowSoBigFlavor extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    )
  }
}

document.addEventListener('turbolinks:load', () => {
  const app = document.getElementById('low-so')
  app && ReactDOM.render(<LowSoBigFlavor />, app)
})


export default LowSoBigFlavor
