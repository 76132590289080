import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

//import homeImage from '../images/cream_cheese.jpg';
import homeImage from '../images/IMG_0035-4.jpg';

import {isMobile} from 'react-device-detect';

import { withStyles, Theme } from '@material-ui/core/styles';
//import { headPanel } from '../stylesheets/headPanel';

const styles = theme => ({
  titleSection: {
      backgroundImage: `url(${homeImage})`,
      backgroundSize: "cover",
      height: "250px",
      maxWidth: "none",
      padding: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderBottom: "20px solid #ff6f00",
  },
  overlay: {
    position: 'relative',
    top: 0,
    height: "250px",
    background: "linear-gradient(-200deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 53%, rgba(0, 0, 0, 0.2) 100%)"
  },

  homeTitle: {
    position: "relative",
    top: 125,
    textAlign: "center",
  },
  mobileTitle: {
    position: "relative",
    top: 75,
    textAlign: "center",
  },

});

class HeadPanel extends React.Component {
 constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;

    return (
      <Container
        className={classes.titleSection}
        style={{backgroundImage: `url(${homeImage})`, width: '100%' }}
        >
        <div className={classes.overlay}>
        <div className={isMobile ? classes.mobileTitle : classes.homeTitle}>
          <Typography
            align="center"
            variant={isMobile ? "h3" : "h2"}
            color="primary"
            >
            {this.props.title}
          </Typography>
        </div>

        </div>
      </Container>

    );
  }
}
export default withStyles(styles, { withTheme: true })(HeadPanel);

