import React from 'react'
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';

import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../components/TabPanel';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Home from './home';
import Recipes from './recipes';
import Ingredients from './ingredients';
import Footer from './footer';

import theme from '../theme/theme';
import main from '../stylesheets/main.css';


const styles = (theme) => ({
  mainWrapper: {
    padding: 0,
    width: '100%',
    height: '100%'
  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'space-between',
    width: '100%',
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  tab: {
    border: 5,
    outline: 'none !important',
    '& button:focus': {
      outline: 'none',
    },
  'Mui-selected': {
    backgroundColor: 'red',
  },
  },
  title: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: 'white',
  },
  noGutter: {
    padding: 0,
  },
  noBorder: {
    border: 'none',
    outline: 'none',
  },
  '.MuiTab-root': {
    '&:focus': {
        outline: 'none',
    }
  }

});


class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
    };
  }

  handleChange(event, newValue) {
    this.setState({value: newValue});
  };


  render() {
    const { classes } = this.props;
    return (
      <div>
        <Container maxWidth={false} className={classes.mainWrapper}>
          <Toolbar
            className={classes.toolbar}>
            <Link
              variant="h6"
              underline="none"
              className={classes.title}
            >
              {'Low Sodium, Big Flavor'}
            </Link>
            <div className={classes.center}>
              <Tabs
                value={this.state.value}
                onChange={(event, newValue) => this.handleChange(event,newValue)}
                indicatorColor="primary"
                textColor="secondary"
                centered
                aria-label="nav tabs"
              >
                <Tab label="Home" />
                <Tab label="Recipes" />
                <Tab label="Meal Plans" />
                <Tab label="Ingredients" />
                <Tab label="Book" />
                <Tab label="About" />

              </Tabs>
            </div>

            <div className={classes.right}>
              <Link
                color="inherit"
                variant="h6"
                underline="none"
                className={classes.leftLink}
                href="http://instagram.com/laraferroni"
              >
                <InstagramIcon fontSize="small" />
              </Link>
            </div>
          </Toolbar>

          <main>
            <TabPanel
              value={this.state.value}
              index={0}
              classes={classes.noGutter}>
              <Home />
            </TabPanel>


            <TabPanel
              value={this.state.value}
              index={1}
              classes={classes.noGutter}>
              <Recipes />
            </TabPanel>

            <TabPanel value={this.state.value} index={2}>
              Meal Plans
            </TabPanel>

            <TabPanel value={this.state.value} index={3}>
              <Ingredients />
            </TabPanel>

            <TabPanel value={this.state.value} index={4}>
              Book
            </TabPanel>

            <TabPanel value={this.state.value} index={5}>
              About
            </TabPanel>

          </main>
        </Container>
        <Footer title="Footer" description="Something here to give the footer a purpose!" />
      </div>
    )
  }
}

export default withStyles(styles)(Main)
