export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1800 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1240 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1240, min: 800 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  }
};