import React from 'react'
import ReactDOM from 'react-dom'

import { Link } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';
import BookIcon from '@material-ui/icons/Book';

import { withStyles } from '@material-ui/core/styles';
//import Link from '@material-ui/core/Link';
import StackGrid, { transitions } from "react-stack-grid";
const { scaleDown } = transitions;

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import homeImage from '../images/IMG_0016-20.jpg';

const styles = theme => ({
  home: {
    marginTop: "80px",
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },
  titleSection: {
    backgroundImage: `url(${homeImage})`,
    backgroundSize: "cover",
    height: "250px",
    maxWidth: "none",
    padding: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "50px",
  },
  overlay: {
    position: 'relative',
    top: 0,
    height: "250px",
    background: "linear-gradient(-200deg, rgba(200, 200, 200, 0.1) 0%, rgba(200, 200, 200, 0.5) 53%, rgba(200, 200, 200, 0.9) 100%)"
  },

  homeTitle: {
    position: "relative",
    top: 100,
    fontSize: "20pt",
    color: "#000",
    textAlign: "center",
  },

  recipeCard: {
    height: "500px",
  },

  photo: {
    maxWidth: '100%',
    height: '500px',
    borderRadius: '.25rem!important',
    verticalAlign: "middle",
    borderStyle: "none",
    backgroundSize: 'cover',
  },
  caption: {
    width: '100%',
    height: "65px",
    position: "absolute",
    bottom: 0,
    backgroundColor: "rgba(254,248,240, .80)",
    color: "rgba(0,0,0,.75)",
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  captionText: {
    flex: 5,
    fontWeight: "600",
    textTransform: "uppercase",
  },

  sodiumBubble: {
    backgroundColor: "#ff6f00",
    borderRadius: "5px 20px 5px",
    color: "#FFF",
    flex: 1,
    fontSize: "8pt",
    fontWeight: "600",
    height: "60px",
    maxWidth: "60px",
    paddingTop: 20,
    textAlign: "center",
    textTransform: "uppercase",

  },

  book: {
    color: theme.palette.secondary.main,
    fontSize: "10pt",
  },

});

class MealPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      meal_plan: {},
      recipes: [],

    }
  }

  async componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    await this.getMealPlan(id);
  }

  async getMealPlan(id) {
     fetch(`/api/v1/meal_plans/${id}`, {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log(data);
      this.setState({meal_plan: data, recipes: data.recipes});
      console.log('meal_plan', this.state.meal_plan, 'recipes', this.state.recipes);

    });
  }

  gotoRecipe() {

  }


  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.home}>


        <Container>
           <Typography
              align="center"
              variant="h2"
              color="secondary">
              {this.state.meal_plan.name}
            </Typography>
           <Typography
              align="center"
              variant="h5">
              {this.state.meal_plan.sodium_per_serving} per meal serving
            </Typography>
        </Container>
        <hr />

         <StackGrid
            columnWidth={350}
            appear={scaleDown.appear}
            appeared={scaleDown.appeared}
            enter={scaleDown.enter}
            entered={scaleDown.entered}
            leaved={scaleDown.leaved}
          >

          {this.state.recipes.map((item, index) => (
            <Link key={index} to={`/recipe/${item.id}`}>
              <div
                className="recipeCard">
                <div
                  className={classes.photo}
                  style={{backgroundImage: `url(${item.thumb_image})` }}
                  alt={item.name} />
                <div className={classes.caption}>
                  <div className={classes.captionText}>
                    {item.name}
                    {( !!item.page_number &&
                    <BookIcon titleAccess={`You can find this recipe in ${item.book_name}`} className={classes.book}/>
                    )}

                  </div>
                  {( !!item.sodium_per_serving &&
                  <div
                    title={`${item.sodium_per_serving} sodium per serving`}
                    className={classes.sodiumBubble}>
                    {item.sodium_per_serving}
                  </div>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </StackGrid>

      </Container>
    )
  }
}


export default withStyles(styles)(MealPlan);
