import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import HeadPanel from '../components/HeadPanel';

import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";



const styles = theme => ({
  tableWrapper: {
    padding: 20,
    width: '100%',
    height: '100%'
  },

  home: {
    maxWidth: "none",
    padding: 0,
    width: "100%",
  },

});

const columns = [
  { field: 'name', title: 'Ingredient' },
  { field: 'qty_formatted', title: 'Quantity' },
  {
    field: 'sodium_qty_formatted',
    title: 'Sodium',
  },
  {
    field: 'source',
    title: 'Source'
  }
];

class Ingredients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ingredients: [],
      rowsPerPage: 50,
    }
  }

  async componentDidMount() {
    await this.getIngredients();
  }

  async getIngredients() {
     fetch('/api/v1/ingredients', {
      method: 'GET',
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      console.log(data);
      this.setState({ingredients: data});
      console.log('ingredients', this.state.ingredients);

    });
  }


  render() {
    const { classes } = this.props;

    return (
      <Container className={classes.home}>

        <HeadPanel title="Sodium by Ingredient"/>

        <Container maxWidth={false} className={classes.tableWrapper}>
          <MaterialTable
            columns={columns}
            data={this.state.ingredients}
            title=""
            stickyHeader="true"
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            rowsPerPage="20"
            options={{
                      headerStyle: { position: 'sticky', top: 0 },
                      maxBodyHeight: 500,
                      pageSize:20
                  }}

          />
        </Container>
      </Container>
    )
  }
}


export default withStyles(styles, { withTheme: true })(Ingredients);
