import React from 'react'
import ReactDOM from 'react-dom'
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import theme from '../theme/theme';

const styles = theme => ({
  footer: {
    bottom: 0,
    marginTop: '50px',
    position: 'relative',
    width: '100%',
    backgroundColor: "#FFF",
    borderTop: "20px solid #ff6f00",
    padding: "20px",
    fontSize: 12,
    color: "#888",
  },
  copyright: {
    color: "#888",
    fontSize: 12,
  }

});

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props;
    return (

      <Container maxWidth={false} className={classes.footer}>
        <Copyright />
      </Container>
    )
  }
}

function Copyright() {

  return (
    <Container className={styles.copyright}>
    <Typography align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://laraferroni.com/">
        Lara Ferroni
      </Link>{' '}
      {new Date().getFullYear()}

      <br />
      Favicon made by <a href="https://www.flaticon.co">flaticon.co</a>
    </Typography>
    </Container>
  );
}

export default withStyles(styles)(Footer);
