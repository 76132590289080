import React, { useEffect } from 'react'
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import { NavLink, Link as RouteLink } from "react-router-dom";

import Link from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import Icon from '@material-ui/core/Icon';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import theme from '../theme/theme';
import main from '../stylesheets/main.css';

const drawerWidth = 240;

const styles = (theme) => ({
  mainWrapper: {
    padding: 0,
    width: '100%',
    height: '100%',

  },
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'space-between',
    width: '100%',
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    zIndex: 1100,
  },
  mobileToolbar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: 64,
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    zIndex: 1100,
  },

  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  tabs: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  tab: {
    flex: 1,
    display: 'flex',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 700,
    lineHeight: 24.5,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  title: {
    fontSize: 19,
    color: theme.palette.text.primary,
  },

  mobile_title: {
    fontSize: 17,
    color: theme.palette.text.primary,
    flex: 4,
  },
  hamburger: {
  },
  menuItems: {

  },
  menuIcons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
  indicator: {
    backgroundColor: 'white',
  }

});


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      mobileView: true,
      drawerOpen: false,
    };

  }

  componentDidMount() {
    const setResponsiveness = () => {
      return window.innerWidth < 1240
        ? this.setState((prevState) => ({ ...prevState, mobileView: true }))
        : this.setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }

  handleChange(event, newValue) {
    this.setState({value: newValue});
  };

  handleDrawer = (open) => {
    this.setState({drawerOpen: open});
  };


  displaySmall = (classes) => {
    return (
      <div>
      <Toolbar className={classes.mobileToolbar }>

        <IconButton
          className={classes.hamburger}
          edge="start"
          color="inherit"
          aria-label="menu"
          aria-haspopup="true"
          onClick={() => this.handleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Link
          variant="h6"
          underline="none"
          className={classes.mobile_title}
          href="/"
        >
          {'Low Sodium, Big Flavor'}
        </Link>
      </Toolbar>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={this.state.drawerOpen}
        onClose={() => this.handleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => this.handleDrawer(false)}>
            {theme.direction !== 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <MenuItem component={RouteLink} to={'/'} onClick={() => this.handleDrawer(false)}>Home</MenuItem>
        <MenuItem component={RouteLink} to={'/recipes'} onClick={() => this.handleDrawer(false)}>Recipes</MenuItem>
        <MenuItem component={RouteLink} to={'/meal_plans'} onClick={() => this.handleDrawer(false)}>Meal Plans</MenuItem>
        <MenuItem component={RouteLink} to={'/ingredients'} onClick={() => this.handleDrawer(false)}>Ingredients</MenuItem>
        <MenuItem component={RouteLink} to={'/eating_out'} onClick={() => this.handleDrawer(false)}>Eating Out</MenuItem>

        <MenuItem component={RouteLink} to={'/book'} onClick={() => this.handleDrawer(false)}>Cookbooks</MenuItem>
        <MenuItem component={RouteLink} to={'/about'} onClick={() => this.handleDrawer(false)}>About</MenuItem>

      </Drawer>
      </div>

      )
  }


  displayLarge = (classes) => {

    return (
      <Toolbar
          className={classes.toolbar }>
          <Link
            variant="h6"
            underline="none"
            className={classes.title}
            href="/"
          >
            {'Low Sodium, Big Flavor'}
          </Link>

          <div className={classes.center, classes.menuItems}>
            <Tabs
              value={this.state.value}
              onChange={(event, newValue) => this.handleChange(event,newValue)}
              indicatorColor="primary"
              textColor="secondary"
              centered
              aria-label="nav tabs"
            >
              <Tab label="Recipes" component={RouteLink} to={'/recipes'} />
              <Tab label="Meal Plans"  component={RouteLink} to={'/meal_plans'} />
              <Tab label="Ingredients" component={RouteLink} to={'/ingredients'}  />
              <Tab label="Eating Out" component={RouteLink} to={'/eating_out'}  />

              <Tab label="Cookbooks" component={RouteLink} to={'/book'}  />
              <Tab label="About" component={RouteLink} to={'/about'}  />

            </Tabs>
          </div>

          <div className={classes.right, classes.menuIcons}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              className={classes.leftLink}
              href="http://instagram.com/laraferroni"
            >
              <InstagramIcon fontSize="small" />
            </Link>
          </div>
        </Toolbar>
      )
  }

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth={false} className={classes.mainWrapper}>
        <AppBar
          position='fixed'>
           {this.state.mobileView ? this.displaySmall(classes) : this.displayLarge(classes)}
        </AppBar>
      </Container>
    )
  }
}

export default withStyles(styles)(Header)
